<template>
  <div class="header">
    <router-link class="header__logo" :to="{ name: 'Dashboard' }">
      <img src="../assets/icons/default/logo_test.svg" alt="" />
    </router-link>

    <div class="header__right">
      <div
        class="notifications"
        :class="[
          { 'notifications--notify': hasReadableNotifications },
          { 'notifications--opened': showNotification },
        ]"
        @click="
          !showNotification
            ? toggleNotification(true)
            : toggleNotification(false)
        "
      >
        <div class="notifications__icon"></div>
      </div>

      <div
        class="account"
        @focus="showAccountDropdown = true"
        @focusout="showAccountDropdown = false"
        tabindex="-1"
      >
        <div class="account__toggler">
          <div v-if="loggedUser.photo" class="account__avatar" style="overflow: hidden;">
            <img :src="loggedUser.photo.thumbnail" alt="User Image" />
          </div>
          <div v-else class="account__avatar account__avatar--empty"></div>
          <div class="account__name">
            <span>{{ $t("header.account.greetings") }}, </span>
            <span>{{ loggedUser.first_name }}</span>
          </div>
          <div class="account__chevron"></div>
        </div>
        <div class="account__dropdown" v-if="showAccountDropdown">
          <div class="account__details">
            <div
              class="account__avatar account__avatar--info account__avatar--empty"
            >
              <div class="account__avatar-edit"></div>
            </div>
            <div
              class="account__dropdown-role"
              v-for="role in loggedUser.roles"
              :key="role.id"
            >
              {{ role.title }}
            </div>
            <div class="account__dropdown-name">
              {{ loggedUser.first_name }} {{ loggedUser.last_name }}
            </div>
            <div class="account__dropdown-email">{{ loggedUser.email }}</div>
          </div>
          <div class="account__links">
            <div class="account__link" @click="goToAccountDetails()">
              <div class="account__link-icon account__link-icon--account"></div>
              {{ $t("header.account.links.accountDetails") }}
            </div>
            <div class="account__link" @click="lock()">
              <div class="account__link-icon account__link-icon--lock"></div>
              {{ $t("header.account.links.lock") }} 
            </div>
            <div class="account__link" @click="goToPasswordModal()">
              <div class="account__link-icon account__link-icon--password"></div>
              {{ $t("header.account.links.pass") }}
            </div>
            <div class="account__link" @click="$store.dispatch('logout')">
              <div class="account__link-icon account__link-icon--logout"></div>
              {{ $t("header.account.links.logOut") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Header",

  props: {
    toggleNotification: {
      required: true,
      type: Function,
    },

    showNotification: {
      required: true,
      type: Boolean,
    },
  },

  data() {
    return {
      showAccountDropdown: false,
    };
  },

  computed: {
    ...mapGetters(["loggedUser", "notifications"]),

    hasReadableNotifications() {
      return this.notifications.some((notification) => !notification.read_at);
    },
  },

  methods: {
    lock() {
      this.$router.push({
        name: "Lock",
      });
    },

    goToAccountDetails() {
      // this.$router.push({
      //   name: "AccountDetails",
      //   params: {
      //     id: this.loggedUser.id,
      //     entity: "profile",
      //   },
      // });

      this.$store.commit("setIsProfileEdit", true);
      this.$store.commit("setShowAddUserModal", true);
    },
  },
};
</script>

<style scoped lang="scss">
.header {
  height: 70px;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: flex-end;
  padding-right: 40px;
  background: var(--bg-1);
  z-index: 7;
  box-shadow: 20px 3px 26px var(--shadow-1);

  &__logo {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;

    img {
      height: 100%;
    }
  }

  &__right {
    color: var(--text-3);
    display: flex;
    align-items: center;
    height: 100%;
  }

  .notifications,
  .account__toggler {
    cursor: pointer;
  }

  .notifications {
    border-left: 1px solid var(--border-7);
    border-right: 1px solid var(--border-7);
    height: 100%;
    padding: 0 15px;
    margin-right: 15px;
    position: relative;
    display: flex;
    align-items: center;

    &__icon {
      background: url("../assets/icons/default/notification_icon.svg") no-repeat
        center;
      height: 24px;
      width: 24px;
    }

    &::after {
      content: "";
      position: absolute;
      display: block;
      width: 3px;
      height: 3px;
      background: var(--bg-1);
      top: 18px;
      right: 10px;
      border: 2px solid var(--border-2);
      border-radius: 50%;
    }

    @keyframes ring {
      0%,
      10% {
        transform: rotate(0deg) scale(1);
      }
      10%,
      15% {
        transform: rotate(-18deg) scale(1.1);
      }
      15%,
      20% {
        transform: rotate(18deg) scale(1.2);
      }
      20%,
      25% {
        transform: rotate(-18deg) scale(1.2);
      }
      25%,
      30% {
        transform: rotate(18deg) scale(1.1);
      }
      30%,
      100% {
        transform: rotate(0deg) scale(1);
      }
    }

    &--notify {
      @keyframes pulse {
        from {
          transform: scale(0.8);
        }
        to {
          transform: scale(1.6);
        }
      }
      .notifications__icon {
        background: url("../assets/icons/default/notification_icon_hover.svg")
          no-repeat center;
        animation: ring 2s linear infinite;
      }

      &::after {
        border: 0;
        background: var(--bg-8);
        width: 6px;
        height: 6px;
        animation: pulse 0.5s linear infinite alternate;
      }
    }

    &--opened {
      .notifications__icon {
        background: url("../assets/icons/default/notification_hover_icon.svg")
          no-repeat center;
      }
    }
  }

  .account,
  .account__avatar {
    position: relative;
  }

  .account {
    &__avatar {
      cursor: pointer;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      border: 2px solid var(--border-2);

      &--info {
        border: 3px solid var(--border-2);
        width: 130px;
        height: 130px;
        margin: 10px auto 6px auto;
      }

      &--empty {
        background: url("../assets/icons/default/nodocs_icon.svg") no-repeat;
        background-size: 70%;
        background-position: center 100%;
      }

      &-edit {
        background: url("../assets/icons/default/edit_icon_header.svg");
        height: 20px;
        width: 20px;
        position: absolute;
        right: -10px;
        top: calc(50% - 10px);
        z-index: 2;
      }
    }

    &__toggler {
      display: flex;
      align-items: center;
    }

    &__name {
      margin: 0 5px 0 10px;
    }

    &__chevron {
      width: 24px;
      height: 24px;
      background: url("../assets/icons/default/down_arrow.svg");
    }

    &__dropdown {
      position: absolute;
      z-index: 2;
      right: 0;
      top: 100%;
      background: var(--bg-1);
      box-shadow: 0px 3px 10px var(--shadow-6);
      border-radius: var(--radius-1);
      width: 355px;

      &-name {
        font-size: var(--medium);
        margin: 2px 0 3px 0;
        color: var(--text-9);
        font-family: var(--muli-text-light);
      }

      &-role {
        color: var(--text-5);
      }

      &-email {
        color: var(--text-13);
      }

      &-role,
      &-email {
        font-size: var(--x-smaller);
      }
    }

    &__details,
    &__links {
      flex: 1;
    }

    &__details {
      text-align: center;
    }

    &__links {
      margin: 30px 0;
      color: var(--text-2);
    }

    &__link {
      padding: 15px 0 15px 100px;
      cursor: pointer;
      display: flex;
      align-items: center;
      transition: all 0.3s ease;

      &-icon {
        height: 24px;
        width: 24px;
        margin-right: 15px;
        transition: all 0.3s ease;

        &--account {
          background: url("../assets/icons/default/account_icon.svg");
        }

        &--lock {
          background: url("../assets/icons/default/lock_icon.svg");
        }

        &--password {
          background: url("../assets/icons/default/password_key.svg");
        }

        &--logout {
          background: url("../assets/icons/default/power_icon.svg");
        }
      }

      &:hover {
        color: var(--text-5);

        .account__link-icon {
          &--account {
            background: url("../assets/icons/default/account_icon_hover.svg");
          }
          &--lock {
            background: url("../assets/icons/default/lock_icon_hover.svg");
          }
          &--password {
            background: url("../assets/icons/default/password_key_hover.svg");
          }
          &--logout {
            background: url("../assets/icons/default/power_icon_hover.svg");
          }
        }
      }
    }
  }
}
</style>
